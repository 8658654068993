<template>
  <b-button
    class="p-50"
    size="sm"
    variant="outline-primary"
  >
    <feather-icon
      :icon="item ? 'ChevronDownIcon' : 'ChevronUpIcon'"
    />
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    item: {
      type: Boolean,
    },
  },
}
</script>
