<template>
  <div class="mobile__resp">
    <!--    <b-col md="4">-->
    <b-card
      no-body
      class="mb-1 paddinggg"
      :class="active === 4 ? 'bg-active cursor-pointer' : 'cursor-pointer'"
      @click="$emit('getActive', 4)"
    >
      <!--        @click="$emit('getActive', 4)"-->
      <div
        class="d-flex align-items-center justify-content-between"
      >
        <!--        <b-avatar-->
        <!--          variant="primary"-->
        <!--          size="30"-->
        <feather-icon
          icon="PhoneCallIcon"
          class="ml-75"
          size="18"
          :class="active === 4 ? 'text-white' : 'text-primary'"
        />
        <!--        </b-avatar>-->

        <div class="truncate d-flex flex-column text-right">
          <h4 class="mb-25 font-weight-bolder">
            {{ dkHeaderSta.manual_call }}
          </h4>

          <span class="fs-13">Звонки от менеджеров</span>
        </div>
      </div>
    </b-card>
    <!--    </b-col>-->

    <!--    <b-col md="4">-->
    <b-card
      no-body
      class="mb-1 paddinggg"
      :class="active === 6 ? 'bg-active cursor-pointer' : 'cursor-pointer'"
      @click="$emit('getActive', 6)"
    >
      <!--        @click="$emit('getActive', 6)"-->
      <div class="d-flex align-items-center justify-content-between">
        <!--        <b-avatar-->
        <!--          variant="primary"-->
        <!--          size="30"-->
        <!--        >-->
        <feather-icon
          class="ml-75"
          size="18"
          :class="active === 6 ? 'text-white' : 'text-primary'"
          icon="PhoneCallIcon"
        />
        <!--        </b-avatar>-->

        <div class="truncate d-flex flex-column text-right">
          <h4 class="mb-25 font-weight-bolder">
            {{ dkHeaderSta.auto_call }}
          </h4>

          <span class="fs-13">Автозвонки</span>
        </div>
      </div>
    </b-card>
    <!--    </b-col>-->

    <!--    <b-col md="3">-->
    <!--      <b-card-->
    <!--        class="mb-1"-->
    <!--        :class="active === 7 ? 'bg-active cursor-pointer' : 'cursor-pointer'"-->
    <!--      >-->
    <!--        &lt;!&ndash;        @click="$emit('getActive', 7)"&ndash;&gt;-->
    <!--        <div class="d-flex align-items-center justify-content-between">-->
    <!--          <b-avatar-->
    <!--            variant="light-primary"-->
    <!--            size="45"-->
    <!--          >-->
    <!--            <feather-icon-->
    <!--              size="21"-->
    <!--              icon="MailIcon"-->
    <!--            />-->
    <!--          </b-avatar>-->

    <!--          <div class="truncate d-flex flex-column text-right">-->
    <!--            <h4 class="mb-25 font-weight-bolder">-->
    <!--              {{ dkHeaderSta.manual_sms }}-->
    <!--            </h4>-->

    <!--            <span class="fs-13">СМС от менеджеров</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </b-card>-->
    <!--    </b-col>-->

    <!--    <b-col md="4">-->
    <b-card
      no-body
      class="mb-1 paddinggg"
      :class="active === 8 ? 'bg-active cursor-pointer' : 'cursor-pointer'"
      @click="$emit('getActive', 8)"
    >
      <!--        @click="$emit('getActive', 8)"-->
      <div class="d-flex align-items-center justify-content-between">
        <!--        <b-avatar-->
        <!--          variant="primary"-->
        <!--          size="30"-->
        <!--        >-->
        <feather-icon
          size="18"
          class="ml-75"
          :class="active === 8 ? 'text-white' : 'text-primary'"
          icon="MailIcon"
        />
        <!--        </b-avatar>-->

        <div class="truncate d-flex flex-column text-right">
          <h4 class="mb-25 font-weight-bolder">
            {{ dkHeaderSta.auto_sms }}
          </h4>

          <span class="fs-13">СМС информирование</span>
        </div>
      </div>
    </b-card>
    <!--    </b-col>-->
  </div>
</template>

<script>
import {
  // BRow,
  // BCol,
  BCard,
  // BAvatar,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    // BRow,
    // BCol,
    BCard,
    // BAvatar,
  },
  props: {
    active: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['dkHeaderSta']),
  },
}
</script>
<style lang="scss">
.bg-active {
  background-color: #147bfc!important;

  h4 {
    color: #fff;
  }

  span {
    color: #fff;
  }
}
</style>
