<template>
  <b-modal
    id="mediaFile"
    centered
    size="xl"
    ok-only
    ok-variant="outline-secondary"
    ok-title="Закрыть"
    no-close-on-backdrop
    :title="mediaValue"
  >
    <pdfTron
      :initial-doc="mediaValue"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import pdfTron from '../../../actions/components/viewFile/pdfTron.vue'

export default {
  components: {
    BModal,
    pdfTron,
  },
  props: {
    mediaValue: {
      type: String,
      default: () => '',
    },
  },
}
</script>
