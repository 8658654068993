<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center justify-content-between">
        <h4>Прикрепленные документы</h4>

        <b-button
          variant="primary"
          @click="openDocAddModal"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить документы
        </b-button>
      </div>
      <BTable
        responsive
        class="my-2 table-bordered"
        :items="attachDoc.results"
        :fields="fields"
        show-empty
        empty-text="Нет данных"
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(file)="props">
          <div class="d-flex flex-wrap">
            <b-button
              v-for="(t, index) in props.item.files"
              :key="index"
              v-b-tooltip.hover.v-primary
              size="sm"
              :title="props.item.file_title"
              variant="outline-primary"
              class="mr-1 mb-1 position-relative"
              @click.stop="downloadImage(t)"
            >
              <b-badge
                variant="danger"
                class="bbg_t"
                @click.stop="deleteFileData(t.id)"
              >
                x
              </b-badge>
              {{ t.file_title }}
            </b-button>
          </div>
        </template>
        <template #cell(tags)="props">
          <div
            v-for="(item, index) in props.item.tags"
            :key="index"
          >
            <b-badge
              variant="success"
              class="mb-50 mr-50"
            >
              {{ item.name }} {{ " " }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="props">
          <BButton
            class="p-50 mr-1"
            variant="warning"
            @click="editDocModal(props.item)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </BButton>
          <BButton
            class="p-50"
            variant="danger"
            @click="deleteDataDocument(props.item.id)"
          >
            <feather-icon icon="XIcon" />
          </BButton>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="[5, 10, 20]"
            class="mx-1"
            @change="refresh"
          />
          <span class="ml-1 text-nowrap"> строк( {{ attachDoc.count }} )</span>
        </div>
        <b-pagination
          v-model="params.page"
          :total-rows="attachDoc.count"
          :per-page="params.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>

    <mediaFileModal :media-value="fileData" />
    <addEditModal
      :edit-doc="editDoc"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BTable, VBTooltip, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import empty from '@/components/empty.vue'
import mediaFileModal from '@/modules/collectorDetail/components/AddEditModal/mediaFileModal.vue'
import addEditModal from './addEditModal.vue'

export default {
  components: {
    BButton,
    BTable,
    BBadge,
    BCard,
    BFormSelect,
    BPagination,
    mediaFileModal,
    addEditModal,
    empty,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        {
          label: 'Дата создания',
          key: 'created_date',
          sortable: true,
        },
        {
          label: 'Дата документа',
          key: 'date_document',
        },
        {
          label: 'Вид документ',
          key: 'tags',
        },
        {
          label: 'Файлы',
          key: 'file',
        },
        {
          label: 'Полезная информация',
          key: 'title',
        },
        {
          label: 'Комментарий',
          key: 'comment',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      fileData: '',
      editDoc: {},
      params: {
        page: 1,
        page_size: 5,
      },
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['attachDoc', 'dkDebt', 'legalContact']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', ['FETCH_ATTACH_DOC']),
    ...mapActions('cases', ['DELETE_DEBT_DOCUMENT', 'DELETE_DEBT_DOCUMENT_FILE_DATA']),

    refresh() {
      this.FETCH_ATTACH_DOC({
        content_type: this.legalContact.sk_borrower_debt.CONTENT_TYPE,
        object_id: this.legalContact.sk_borrower_debt.id,
        ...this.params,
      })
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },

    openDocAddModal() {
      this.editDoc = {}
      this.$nextTick(() => {
        this.$bvModal.show('addEditDoc')
      })
    },

    downloadImage(t) {
      this.fileData = t.file
      this.$nextTick(() => {
        this.$bvModal.show('mediaFile')
      })
    },

    deleteFileData(id) {
      this.$_showAreYouSureModal()
        .then(e => {
          if (e) {
            this.DELETE_DEBT_DOCUMENT_FILE_DATA(id)
              .then(() => {
                this.refresh()
              })
          }
        })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    editDocModal(item) {
      this.editDoc = item
      this.$nextTick(() => {
        this.$bvModal.show('addEditDoc')
      })
    },
  },
}
</script>
<style>
.file__w {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.bbg_t {
  position: absolute!important;
  right: -8px!important;
  top: -8px!important;
}
</style>
