<template>
  <div>
    <BModal
      id="addEdiClaimstDoc"
      centered
      :title="typeOfTitle"
      :ok-variant="typeOfVariant"
      ok-title="Сохранить"
      cancel-variant="outline-secondary"
      cancel-title="Отмена"
      responsive
      no-close-on-backdrop
      @show="openShowModal"
      @ok.prevent="createDoc"
      @hidden="$emit('hideModal')"
    >
      <!--      <pre>{{ editDoc }}</pre>-->
      <!--      <validation-observer ref="docValue">-->
      <b-form-group label="Дата документа">
        <!--          <validation-provider-->
        <!--            #default="{ errors }"-->
        <!--            name="Дата документа"-->
        <!--            rules=""-->
        <!--       >-->
        <input
          v-model="formData.date_document"
          type="date"
          class="form-control"
        >
        <!--            <datepicker-->
        <!--              id="passport_expire_date"-->
        <!--              v-model="formData.date_document"-->
        <!--              :bootstrap-styling="true"-->
        <!--              :clear-button="true"-->
        <!--              :language="ru"-->
        <!--              :calendar-button="true"-->
        <!--              format="dd.MM.yyyy"-->
        <!--              placeholder="ДД.ММ.ГГГГ"-->
        <!--              calendar-button-icon="fa fa-calendar"-->
        <!--              clear-button-icon="fa fa-times"-->
        <!--              input-class="bg-white"-->
        <!--              @input="onSelectDate"-->
        <!--            />-->
        <!--            <small class="text-danger">{{ errors[0] }}</small>-->
        <!--          </validation-provider>-->
      </b-form-group>
      <!--{{formData.tags}}-->
      <b-form-group label="Вид документа">
        <div class="d-flex align-items-center">
          <v-select
            v-model="formData.tags"
            class="w-100"
            multiple
            label="name"
            :reduce="p => p.id"
            :options="utilsDocType.results"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>

          <b-button
            class="ml-50"
            size="sm"
            variant="success"
            @click="openDocTypeModal"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </b-form-group>

      <b-form-group
        v-if="additional1"
        label="Вид действия"
      >
        <div class="d-flex align-items-center">
          <v-select
            v-model="formData.action_type"
            class="w-100"
            label="name"
            :reduce="p => p.id"
            :options="document_types.results"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <b-button
            class="ml-50"
            size="sm"
            variant="success"
            @click="openDocTypeModal"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </b-form-group>
      <b-form-group label="Файлы">
        <div class="d-flex align-items-center">
          <BFormFile
            ref="file-input"
            v-model="formData.file"
            multiple
            :placeholder="formData.file_title ? formData.file_title : 'Выбрать файл'"
          />
          <b-button
            size="sm"
            class="p-50 ml-50"
            variant="danger"
            @click="clearFiles"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </b-form-group>
      <b-form-group
        label="Полезная информация"
      >
        <BFormInput
          v-model="formData.title"
        />
      </b-form-group>

      <b-form-group label="Комментарий">
        <b-form-textarea
          v-model="formData.comment"
        />
      </b-form-group>
      <!--      </validation-observer>-->
    </BModal>

    <typeDocument
      :modal-id="modalId"
      :type="type"
    />

    <addActionTypeModal />
  </div>
</template>

<script>
import {
  BModal,
  BFormFile,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import typeDocument from '@/components/typeDocument/index.vue'
import addActionTypeModal from '@/components/modalDocDebts/addActionTypeModal.vue'
// import { required } from '@core/utils/validations/validations'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'AddEditModal',
  components: {
    BModal,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    typeDocument,
    addActionTypeModal,
    // ValidationProvider,
    // ValidationObserver,
  },
  props: {
    formData: {
      type: Object,
      default: () => {
      },
    },
    editDoc: {
      type: Object,
      default: () => {
      },
    },
    additional1: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      // required,
      ru,
      modalId: 'typeDoc2',
      // type: 'borrower',
    }
  },
  computed: {
    ...mapState('reference', ['utilsDocType', 'document_types']),

    typeOfTitle() {
      return this.editDoc.id ? 'Редактировать документы' : 'Добавить документы'
    },

    typeOfVariant() {
      return this.editDoc.id ? 'warning' : 'primary'
    },
  },
  mounted() {
    this.FETCH_DOCUMENT_TYPES({ page_size: 1000 })
  },
  methods: {
    ...mapActions('reference', ['FETCH_UTILS_DOC_TYPE', 'FETCH_DOCUMENT_TYPES']),
    rewriteDate(date) {
      // I was forced because of the date that came to me
      if (date) {
        const rewriteDate = date.split('.')
        const temp = rewriteDate[0]
        // eslint-disable-next-line prefer-destructuring
        rewriteDate[0] = rewriteDate[1]
        rewriteDate[1] = temp
        return rewriteDate.join('.')
      }
      return date
    },

    openDocTypeModal() {
      this.$nextTick(() => {
        this.$bvModal.show(this.modalId)
      })
    },

    clearFiles() {
      this.$refs['file-input'].reset()
    },

    createDoc() {
      try {
        if (this.editDoc.id) {
          this.$emit('editDocuments')
          this.$emit('hideModal')
        } else if (this.formData.date_document) {
          this.$emit('createDoc')
          this.$emit('hideModal')
        }
      } catch (e) {
        console.log(e, 'dsafdas')
      }
    },

    // eslint-disable-next-line consistent-return
    onSelectDate(value) {
      if (value) {
        return moment(value).format('YYYY-MM-DD')
      }
    },

    openShowModal() {
      this.FETCH_UTILS_DOC_TYPE({
        type: this.type,
        page_size: 1000,
      })
      if (this.editDoc.id) {
        let files = ''
        files += this.editDoc.files.map(el => ` ${el.file_title}`)
        this.formData.date_document = this.onSelectDate(this.rewriteDate(this.editDoc.date_document))
        this.formData.title = this.editDoc.title
        this.formData.file_title = files
        this.formData.action_type = this.editDoc.action_type?.id
        this.formData.comment = this.editDoc.comment
        this.formData.tags = this.editDoc.tags.map(e => e.id)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
