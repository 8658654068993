<template>
  <div style="user-select: none">
    <slot />
    <div
      ref="observerRef"
      style="height: 2px; width: 100%"
    >
      <p
        class="mb-0"
        style="color: transparent"
      >
        -
      </p>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    otherParams: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    storeFetch: {
      type: Function,
      required: true,
    },
    storeList: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.createObserver()
  },
  beforeUnmount() {
    this.disconnectObserver()
  },
  methods: {
    createObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }

      const callback = debounce(async (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && this.storeList.next) {
            this.fetchNextPage()
          }
        })
      }, 300) // Debounce delay in milliseconds

      this.observer = new IntersectionObserver(callback, options)
      if (this.$refs.observerRef) {
        this.observer.observe(this.$refs.observerRef)
      }
    },
    disconnectObserver() {
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    async fetchNextPage() {
      await this.storeFetch({
        page: this.storeList.next,
        ...this.otherParams,
      })
    },
  },
}
</script>
