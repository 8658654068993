<template>
  <div>
    <b-card title="Другие дела задолжника">
      <BTable
        class="table-bordered"
        :items="dkDebtOther.results"
        :fields="fields2"
        :busy="loader"
        show-empty
        responsive
      >
        <template #empty>
          <empty />
        </template>
        <template #table-busy>
          <div class="d-flex align-items-center flex-column p-3">
            <b-spinner
              variant="primary"
            />
            <span>Загрузка...</span>
          </div>
        </template>
        <template #cell(pay_his)="props">
          <div v-if="props.item.BASIS">
            <div
              v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
              :key="index"
            >
              <ul
                class="d-flex align-items-center justify-content-between mb-0"
              >
                <li style="font-size: 11px">
                  {{ e.PAY_DATE }}
                </li>
                <small class="ml-50">
                  {{ $_moneyFormatter(e.PAY_AMOUNT) }},
                </small>
              </ul>
            </div>
          </div>
        </template>

        <template #cell(debtors)="data">
          <div
            class="text-primary cursor-pointer"
            @click="changeRoute(data)"
          >
            <!--           <router-link-->
            <!--               v-if="data.item.BORROWER"-->
            <!--               :to="`/legalPortfoliosCollector/${data.item.id}`"-->
            <!--           >-->
            {{ data.item.BORROWER.FULL_NAME }}
            <!--           </router-link>-->
          </div>
        </template>

        <template #cell(BASIS)="props">
          <div v-if="props.item.BASIS">
            <span v-if="props.item.BASIS.DO_STAGE === 'voluntary_payment'">Добровольная оплата</span>
            <span v-else-if="props.item.BASIS.DO_STAGE === 'claim'">Претензия</span>
            <span v-if="props.item.BASIS.DO_STAGE === 'judical_work'">Судебная работа</span>
            <span v-else-if="props.item.BASIS.DO_STAGE === 'executive_document'">Исполнительный документ</span>
            <span v-if="props.item.BASIS.DO_STAGE === 'executive_work'">Исполнительное производство</span>
            <span v-else-if="props.item.BASIS.DO_STAGE === 'stopped'">Приостановлено</span>
            <span v-if="props.item.BASIS.DO_STAGE === 'paid'">Погашен</span>
          </div>
        </template>

        <template #cell(SUM)="props">
          <span v-if="props.item.BASIS && props.item.BASIS.DEBT">
            {{ $_moneyFormatter(props.item.BASIS.DEBT) }}
          </span>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="[5, 10, 20]"
            class="mx-1"
            @change="refresh"
          />
          <span class="ml-1 text-nowrap"> строк( {{ dkDebtOther.count > 1 ? dkDebtOther.count - 1 : dkDebtOther.count }} )</span>
        </div>
        <b-pagination
          v-model="params.page"
          :total-rows="dkDebtOther.count"
          :per-page="params.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormSelect, BPagination, BTable, BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { fields2 } from '@/modules/portfolios/constants'
import Empty from '@components/empty.vue'

export default {
  components: {
    BCard,
    Empty,
    BTable,
    BFormSelect,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      fields2,
      loader: false,
      params: {
        page: 1,
        page_size: 5,
      },
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['dkDebt', 'dkDebtOther', 'legalContact']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', [
      'FETCH_DK_DEBT_OTHER', 'FETCH_DK_DEBT_ID', 'FETCH_LEGAL_CONTACT', 'FETCH_LEGAL_JUDICAL_WORK', 'FETCH_LEGAL_JUDICAL_EXECUTION',
    ]),
    ...mapMutations('legalPortfoliosCollector', ['SET_ACTIVE', 'SET_CALL']),

    async refresh() {
      try {
        this.loader = true
        await this.FETCH_DK_DEBT_OTHER({
          BORROWER: this.$route.params.id,
          ...this.params,
        }).then(() => {
          const arr = this.dkDebtOther.results

          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === this.legalContact.sk_borrower_debt.id) {
              arr.splice(i, 1)
            }
          }
        })
      } catch (e) {
        this.loader = false
      }
      this.loader = false
    },

    changeRoute(data) {
      this.SET_CALL(false)
      this.FETCH_LEGAL_CONTACT({
        id: this.$route.params.id,
        params: {
          debt_id: data.item.id,
        },
      })
        .then(() => {
          this.$emit('globalFetch')
          this.FETCH_LEGAL_JUDICAL_WORK({ DOSSIER_ID: this.legalContact.sk_borrower_debt.id })
          this.FETCH_LEGAL_JUDICAL_EXECUTION({ DOSSIER_ID: this.legalContact.sk_borrower_debt.id })
        })
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },
  },
}
</script>
