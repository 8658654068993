<template>
  <BModal
    id="modalComment"
    centered
    title="Комментарии"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    responsive
    no-close-on-backdrop
    @show="openShowModal"
  >
    <div
      class="modal__comment px-1 mt-2"
    >
      <div
        v-for="(items, index) in commentMessage"
        :key="index"
        class="d-flex justify-content-between align-items-center mb-1"
      >
        <BMedia no-body>
          <BMediaAside>
            <BAvatar
              v-b-tooltip.hover
              size="42"
              class="pull-up"
              :src="items.author.profile_picture"
              variant="primary"
              :title="items.author.first_name"
            />
          </BMediaAside>
          <BMedia-body>
            <h6>{{ items.message }}</h6>
            <div class="d-flex">
              <small>{{ items.created_date }}</small>
            </div>
          </BMedia-body>
        </BMedia>
        <div
          class="d-flex align-items-center"
        >
          <small
            class="mb-0 text-muted mr-1 cursor-pointer"
            @click="$emit('deleteComment', items.id)"
          >
            Удалить
          </small>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <BFormTextarea
        v-model="comments.message"
        placeholder="Написать комментарии..."
      />
    </div>
    <div class="d-flex justify-content-end">
      <BButton
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="$bvModal.hide('modalComment')"
      >
        Отменить
      </BButton>
      <BButton
        class="mt-2"
        variant="primary"
        type="submit"
        :disabled="!comments.message"
        @click="$emit('createComment')"
      >
        Сохранить
      </BButton>
    </div>
  </BModal>
</template>

<script>
import {
  BModal,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BButton,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'TheDebtorIncomeModal',
  components: {
    BModal,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    comment: {
      type: Object,
      default: () => {
      },
    },
    commentMessage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      comments: {},
    }
  },
  methods: {
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },

    openShowModal() {
      this.comments = this.comment
      this.$emit('refresh')
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.select-w-z-index {
  min-width: 250px;
  z-index: 9999;
}

.modal__comment{
  height: 350px;
  overflow: auto;
}
</style>
