<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <div
        v-if="$route.query.play"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <b-button
          :disabled="currentId.prev_id === null"
          :variant="currentId.prev_id === null ? 'secondary' : 'primary'"
          @click="prevPage"
        >
          <feather-icon
            icon="ChevronLeftIcon"
          />
          Предыдущий
        </b-button>

        <b-button
          :disabled="currentId.next_id === null"
          :variant="currentId.next_id === null ? 'secondary' : 'primary'"
          @click="nextPage"
        >
          Следующий
          <feather-icon icon="ChevronRightIcon" />
        </b-button>
      </div>

      <b-button
        v-if="!$route.query.play"
        class="mb-1"
        @click="backToHome"
      >
        <svg
          fill="currentColor"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          class="material-design-icon__svg"
        >
          <path d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z" />
        </svg>
        Назад
      </b-button>

      <b-row>
        <b-col md="3">
          <verticalSide />
        </b-col>

        <b-col md="9">
          <topStatistics @globalFetch="globalFetch" />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import verticalSide from './collectorDetail/verticalSide.vue'
import topStatistics from './collectorDetail/topStatistics.vue'

export default {
  beforeRouteUpdate(to, from, next) {
    const newId = to.params.id

    this.FETCH_LEGAL_CONTACT({
      id: newId,
    })
      .then(() => {
        this.FETCH_LEGAL_JUDICAL_WORK({ DOSSIER_ID: this.legalContact.sk_borrower_debt.id })
        this.FETCH_LEGAL_JUDICAL_EXECUTION({ DOSSIER_ID: this.legalContact.sk_borrower_debt.id })
      })

    this.historyTimeLine.results = []

    const params = {
      page: 1,
      page_size: 5,
    }
    this.historyTimeLine.results = []
    this.FETCH_HISTORY_TIMELINE({
      page_size: 5,
      debt: this.legalContact.sk_borrower_debt.id,
      page: 1,
    })
    this.FETCH_PROMISE_LIST({ debt: this.legalContact.sk_borrower_debt.id, ...params })
    this.FETCH_DK_HEADER_STA({ debt_id: this.legalContact.sk_borrower_debt.id })

    this.SET_ACTIVE(3)
    this.SET_CALL(false)
    next()
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    verticalSide,
    topStatistics,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['historyTimeLine', 'legalContact']),
    ...mapState('dkLegalFace', ['currentId']),
  },
  mounted() {
    if (this.$route.query.play) {
      this.FETCH_CURRENT_ID({
        current_id: this.currentId.current_id,
        old_id: this.currentId.prev_id,
      })
    }
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', [
      'FETCH_DK_DEBT_ID', 'FETCH_HISTORY_TIMELINE', 'FETCH_PROMISE_LIST', 'FETCH_LEGAL_CONTACT', 'FETCH_LEGAL_JUDICAL_WORK', 'FETCH_LEGAL_JUDICAL_EXECUTION',
      'FETCH_DK_HEADER_STA',
    ]),
    ...mapActions('dkLegalFace', ['FETCH_CURRENT_ID']),
    ...mapMutations('legalPortfoliosCollector', ['SET_ACTIVE', 'SET_CALL']),

    prevPage() {
      this.loading = true
      this.FETCH_CURRENT_ID({
        current_id: this.currentId.prev_id,
        old_id: this.currentId.prev_id,
        action: 'prev',
      })
        .then(() => {
          this.SET_CALL(false)
          this.$router.push({
            name: 'legal-collector-id',
            query: { play: 'true' },
            params: {
              id: this.currentId.current_id,
            },
          })
          this.loading = false
        })
        .catch(e => {
          if (e && e.message === 'Request failed with status code 403') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Нужно выполнить действие и заполнить комментарий',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            }, {
              position: 'bottom-right',
            })
            this.loading = false
          } else {
            this.$_errorToast(e)
            this.loading = false
          }
        })
    },

    nextPage() {
      this.loading = true
      this.FETCH_CURRENT_ID({
        current_id: this.currentId.next_id,
        old_id: this.currentId.current_id,
        action: 'next',
      })
        .then(() => {
          this.SET_CALL(false)
          this.$router.push({
            name: 'legal-collector-id',
            query: { play: 'true' },
            params: {
              id: this.currentId.current_id,
            },
          })
          this.loading = false
        })
        .catch(e => {
          if (e && e.message === 'Request failed with status code 403') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Нужно выполнить действие и заполнить комментарий',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            }, {
              position: 'bottom-right',
            })
            this.loading = false
          } else {
            this.$_errorToast(e)
            this.loading = false
          }
        })
    },

    backToHome() {
      this.globalFetch()
      this.$router.push('/legal-portfolios')
    },

    globalFetch() {
      this.SET_CALL(false)
      this.historyTimeLine.results = []
      this.SET_ACTIVE(3)
    },
  },
}
</script>
