<template>
  <b-modal
    id="modalFileSecond"
    ok-only
    ok-variant="outline-secondary"
    ok-title="Закрыть"
    centered
    size="xl"
    :title="fileData.file_title"
  >
    <div class="text-right">
      <b-button
        class="mb-1"
        variant="success"
        @click="download(fileData.file)"
      >
        <feather-icon
          icon="DownloadIcon"
        />
        Скачать
      </b-button>
      <pdfTron
        :initial-doc="fileData.file"
      />
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import pdfTron from '../../modules/actions/components/viewFile/pdfTron.vue'

export default {
  name: 'Modal',
  components: {
    BModal,
    BButton,
    pdfTron,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    fileData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    download(url) {
      // eslint-disable-next-line camelcase
      const url_parts = url.split('/')
      fetch(url)
        .then(t => t.blob()
          .then(b => {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(b)
            a.setAttribute('download', url_parts[url_parts.length - 1])
            a.click()
          }))
    },
  },
}
</script>
