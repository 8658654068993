<template>
  <div>
    <b-card title="Звонки от менеджеров">
      <BTable
        class="table-bordered"
        :items="dkHeaderCallHistory.results"
        :fields="fields"
        show-empty
        responsive
        :busy="loader"
        empty-text="Нет данных"
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(status)="props">
          <b-badge
            v-if="props.item.status"
            variant="primary"
          >
            {{ props.item.status }}
          </b-badge>
        </template>
        <template #cell(phone)="props">
          {{ formattedPhoneNumber(props.item.phone) }}
        </template>
        <template #table-busy>
          <div class="d-flex align-items-center flex-column p-3">
            <b-spinner
              variant="primary"
            />
            <span>Загрузка...</span>
          </div>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="['5', '10', '15']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="ml-1 text-nowrap"> строк( {{ dkHeaderCallHistory.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="params.page"
            :total-rows="dkHeaderCallHistory.count"
            :per-page="params.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BBadge, BCard, BFormSelect, BPagination, BSpinner, BTable,
} from 'bootstrap-vue'
import empty from '@components/empty.vue'

export default {
  components: {
    BSpinner,
    BFormSelect,
    BBadge,
    empty,
    BTable,
    BCard,
    BPagination,
  },
  data() {
    return {
      loader: false,
      fields: [
        {
          label: 'Дата создания',
          key: 'created_date',
        },
        {
          label: 'Номер телефона',
          key: 'phone',
        },
        {
          label: 'Оператор',
          key: 'user.full_name',
        },
        {
          label: 'Статус',
          key: 'status',
        },
      ],
      params: {
        page_size: 5,
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['legalContact', 'dkHeaderCallHistory']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', ['FETCH_DK_HEADER_CALL_HISTORY']),

    async refresh() {
      try {
        this.loader = true
        if (this.legalContact.sk_borrower_debt.id) {
          await this.FETCH_DK_HEADER_CALL_HISTORY({
            debt: this.legalContact.sk_borrower_debt.id,
            is_robot: false,
          })
        }
      } catch (e) {
        this.loader = false
        await this.$_errorToast()
      }
      this.loader = false
    },

    changePerPage(pageSize) {
      this.params.page_size = pageSize

      this.refresh()
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },

    formattedPhoneNumber(value) {
      const regex = /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/
      const matches = value.match(regex)

      if (matches) {
        return `+${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}-${matches[5]}`
      }

      return ''
    },
  },
}
</script>
