<template>
  <div>
    <b-card title="История платежей">
      <BTable
        class="my-2 table-bordered"
        :items="paymentHistory.results"
        :fields="fields"
        show-empty
        responsive
        :busy="loader"
        empty-text="Нет данных"
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(PAY_AMOUNT)="props">
          {{ $_moneyFormatter(props.item.PAY_AMOUNT) }}
        </template>
        <template #table-busy>
          <div class="d-flex align-items-center flex-column p-3">
            <b-spinner
              variant="primary"
            />
            <span>Загрузка...</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <p
            class="cursor-pointer text-underline text-primary mb-0"
            @click="openEdit(data.item)"
          >
            Подробно
          </p>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="['5', '10', '15']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="ml-1 text-nowrap"> строк( {{ paymentHistory.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="params.page"
            :total-rows="paymentHistory.count"
            :per-page="params.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>

    <ThePaymentEdit
      ref="paymentEdit"
      :debtor="debtor"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import empty from '@components/empty.vue'
import {
  BCard, BFormSelect, BPagination, BSpinner, BTable,
} from 'bootstrap-vue'
import ThePaymentEdit from '@/modules/caseCard/components/ThePaymentEdit.vue'

export default {
  components: {
    empty,
    BTable,
    BCard,
    BPagination,
    BFormSelect,
    BSpinner,
    ThePaymentEdit,
  },
  data() {
    return {
      loader: false,
      fields: [
        {
          key: 'PAY_DATE',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'PAY_AMOUNT',
          label: 'Сумма',
          sortable: true,
        },
        {
          key: 'id',
          label: 'ИД',
        },
        {
          key: 'sign_admission',
          label: 'Признак поступления ',
        },
        {
          key: 'collector_share',
          label: 'Доля коллектора ',
        },
        {
          key: 'not_credited_manager',
          label: 'Не засчитывается менеджеру ',
        },
        {
          key: 'actions',
          label: 'Действия',
        },
      ],
      params: {
        page_size: 5,
        page: 1,
      },
      debtor: {},
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['paymentHistory', 'legalContact']),
  },
  async mounted() {
    try {
      this.loader = true
      await this.FETCH_DK_PAYMENT_HISTORY({ id: this.legalContact.sk_borrower_debt.id, params: this.params })
    } catch (e) {
      this.loader = false
    }
    this.loader = false
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', ['FETCH_DK_PAYMENT_HISTORY', 'FETCH_DK_PAYMENT_HISTORY']),

    openEdit(item) {
      this.debtor = item
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.paymentEdit.open()
        }, 100)
      })
    },

    changePerPage(pageSize) {
      this.params.page_size = pageSize

      this.FETCH_DK_PAYMENT_HISTORY({
        id: this.$route.params.id,
        params: this.params,
      })
    },

    changePage(page) {
      this.params.page = page

      this.FETCH_DK_PAYMENT_HISTORY({
        id: this.$route.params.id,
        params: this.params,
      })
    },
  },
}
</script>
