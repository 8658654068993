<template>
  <div>
    <b-modal
      :id="modalId"
      title="Вид документ"
      ok-only
      centered
      ok-title="Сохранить"
      @ok.prevent="onSubmit"
      @hidden="onHide"
    >
      <b-list-group class="mb-1">
        <b-list-group-item
          v-for="(item, index) in utilsDocType.results"
          :key="index"
          class="d-flex align-items-center justify-content-between"
        >
          <span>{{ item.name }}</span>
          <div class="d-flex align-items-center">
            <b-button
              variant="warning"
              class="p-25 mr-25"
              @click="onUpdate(item)"
            >
              <feather-icon
                icon="EditIcon"
                size="20"
              />
            </b-button>
            <b-button
              variant="danger"
              class="p-25"
              @click="onDelete(item.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="20"
              />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>

      <b-form-group label="Вид документ">
        <b-form-input
          v-model="data.name"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BModal,
  VBModal,
  BFormInput,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'

export default {
  name: 'Index',
  components: {
    BModal,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [areYouSure],
  props: {
    modalId: {
      type: String,
      default: () => '',
    },
    // executer: {
    //   type: Object,
    //   default: () => {},
    // },
    type: {
      type: String,
      default: () => '',
    },
    // handleTrue: {
    //   type: Boolean,
    //   default: () => false,
    // },
  },
  data() {
    return {
      data: {
        name: '',
      },
      isEdit: false,
    }
  },
  computed: {
    ...mapState('reference', ['utilsDocType']),
  },
  methods: {
    ...mapActions('reference', ['ADD_UTILS_DOC_TYPE', 'FETCH_UTILS_DOC_TYPE', 'UPDATE_UTILS_DOC_TYPE', 'DELETE_UTILS_DOC_TYPE']),

    onUpdate(data) {
      this.data.name = data.name
      this.data.id = data.id
      this.isEdit = true
    },

    onDelete(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_UTILS_DOC_TYPE(id)
            .then(() => {
              this.FETCH_UTILS_DOC_TYPE({
                type: this.type,
              })

              this.data = {
                name: '',
              }
              this.isEdit = false
            })
            .catch(() => {
              this.$_errorToast()
            })
        }
      })
    },

    onHide() {
      this.data = {
        name: '',
      }
      this.isEdit = false
    },

    async onSubmit() {
      try {
        if (!this.isEdit) {
          await this.ADD_UTILS_DOC_TYPE({
            type: this.type,
            ...this.data,
          })
        } else {
          await this.UPDATE_UTILS_DOC_TYPE({
            type: this.type,
            ...this.data,
          })
        }
        await this.FETCH_UTILS_DOC_TYPE({
          type: this.type,
        })
        // await this.FETCH_UTILS_DOC_TYPE_LAZY_SECOND({
        //   content_type_id: this.executer?.CONTENT_TYPE,
        //   type: this.type,
        // })

        this.$bvModal.hide(this.modalId)
      } catch (error) {
        await this.$_errorToast()
      }
    },
  },
}
</script>
