<template>
  <div>
    <!--    auto sms-->
    <!--    <pre>{{ dkHeaderSmsHistory }}</pre>-->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('legalPortfoliosCollector', ['legalContact', 'dkHeaderSmsHistory']),
  },
  mounted() {
    if (this.legalContact.sk_borrower_debt.id) {
      this.FETCH_DK_HEADER_SMS_HISTORY({ debt: this.legalContact.sk_borrower_debt.id, send_type: 'auto' })
    }
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', ['FETCH_DK_HEADER_SMS_HISTORY']),
  },
}
</script>
