<template>
  <b-modal
    id="manualSms"
    title="Ручная отправка смс"
    centered
    no-close-on-backdrop
    ok-title="Сохранить"
    cancel-title="Отмена"
  >
    <b-form-group label="Шаблон смс">
      <v-select />
    </b-form-group>

    <b-form-group label="График просрочки">
      <v-select />
    </b-form-group>

    <b-form-group label="Номер телефона">
      <b-form-input />
    </b-form-group>

    <b-form-group label="СМС">
      <b-form-textarea />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    VSelect,
  },
}
</script>

<style scoped>

</style>
