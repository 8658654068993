<template>
  <BModal
    v-model="modalState"
    centered
    ok-title="Сохранить"
    cancel-title="Удалить"
    cancel-variant="danger"
    :title="title"
    no-close-on-backdrop
    class="list-items-views-edit"
    @cancel="deleteHistory"
    @ok="updateHistory"
  >
    <div slot="modal-footer" />
    <div class="d-flex justify-content-end align-items-center w-100">
      <BButton
        class="rounded-circle mr-50 p-50 mb-50"
        @click="editModal = !editModal"
      >
        <feather-icon icon="EditIcon" />
      </BButton>
    </div>
    <div>
      <div
        v-for="(item, key) in keys"
        :key="key"
      >
        <div
          v-if="item.header"
          class="mb-1"
        >
          <h3 class="text-bold text-center font-weight-bold">
            {{ item.title }}
          </h3>
        </div>
        <div
          v-else
          class="d-flex w-100 my-50"
        >
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              {{ item.title || item }}
            </p>
          </div>
          <div class="w-50">
            <!--  если модалка для редактирования то ставлю input если нет то ставлю параграф  -->
            <component
              :is="getTypeComponent(item)"
              v-if="editModal"
              v-model="formData[key]"
              :options="options[key]"
              :get-option-label="item ? item.reduce : null"
              class="mb-0"
              show-format="YYYY.MM.DD"
              @click="() => item.click && item.click()"
            >
              {{
                item.reduce ? item.reduce(formData[key]) : formData[key]
              }}
            </component>
            <div v-if="item.type === 'BFormInput' && !editModal">
              {{ $_moneyFormatter(formData[key]) }}
            </div>
            <div v-else-if="item.type !== 'BFormInput' && !editModal">
              {{ formData[key] }}
            </div>
            <!--            <div v-else-if="item.type == 'VSelect' && !editModal">-->
            <!--              {{ formData[key].title }}-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { BModal, BFormInput, BButton } from 'bootstrap-vue'
import VcDatePicker from '@/components/VcDatePicker.vue'
import VSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    BModal,
    BButton,
    VcDatePicker,
    BFormInput,
    VSelect,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    keys: {
      type: [Array, Object],
      default: () => [],
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {
      },
    },

    defaultType: {
      type: String,
      default: 'BFormInput',
    },
  },
  data() {
    return {
      modalState: false,
      editModal: false,
      formData: {},
    }
  },
  methods: {
    ...mapActions('cases', [
      'UPDATE_SK_DEBT_HISTORY_MODAL',
      'FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL',
      'DELETE_SK_DEBT_HISTORY_MODAL',
    ]),

    open() {
      this.modalState = true
      this.formData = JSON.parse(JSON.stringify(this.inputData))
    },

    close() {
      this.modalState = false
    },

    getTypeComponent(item) {
      if (this.editModal && !item.not_editable) {
        // если модалка в режиме редактирования то выбираю компонент для редактирования  или дефолтный если нет то просто параграф
        return item.type || this.defaultType
      }
      return 'p'
    },

    updateHistory() {
      this.UPDATE_SK_DEBT_HISTORY_MODAL({
        id: this.inputData.id,
        ...this.formData,
      })
        .then(() => {
          this.FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ id: this.$route.params.id })
        })
    },

    deleteHistory() {
      this.DELETE_SK_DEBT_HISTORY_MODAL(this.inputData.id)
        .then(() => {
          this.FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ id: this.$route.params.id })
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.list-items-views-edit p {
  margin-bottom: 0;
}
</style>
