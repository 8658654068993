<template>
  <b-modal
    id="modal-doc-type-all"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    ok-title="Сохранить"
    ok-variant="primary"
    cancel-title="Отменить"
    centered
    title="Добавите вид действия"
    @ok.prevent="onSubmitDoc"
  >
    <validation-observer ref="docValidation">
      <b-form-group
        label="Наименование"
      >
        <validation-provider
          #default="{ errors }"
          name="Наименование"
          rules="required"
        >
          <b-form-input
            v-model="doc.name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Ссп">
        <b-form-checkbox
          v-model="doc.is_ssp"
          name="check-button"
          switch
          inline
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      doc: {
        name: '',
        is_ssp: false,
      },
    }
  },
  methods: {
    ...mapActions('reference', ['ADD_DOCUMENT_TYPE', 'FETCH_DOCUMENT_TYPES']),

    onSubmitDoc(bvModalEvt) {
      bvModalEvt.preventDefault()

      const req = this.$refs.docValidation.validate()
      if (!req) return

      this.ADD_DOCUMENT_TYPE(this.doc)
        .then(() => {
          this.FETCH_DOCUMENT_TYPES()
          this.$nextTick(() => {
            this.$bvModal.hide('modal-doc-type-all')
          })
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

  },
}
</script>
