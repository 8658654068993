<template>
  <div class="client_info">
    <b-overlay
      :show="busy"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-button
        variant="primary"
        class="d-flex align-items-center justify-content-between w-100 mb-1"
        @click="update3RD"
      >
        <span v-if="legalContact.sk_borrower_debt.BASIS">
          Отказ от взаимодействия с 3ми лицами
        </span>
        <span>{{ legalContact.sk_borrower_debt.BASIS.REFUSED_FROM_3RD_PARTIES === 'Y' ? 'Да' : 'Нет' }}</span>
      </b-button>
    </b-overlay>

    <!--    Выписка по должнику-->
    <b-card
      no-body
      class="p-1 mb-1"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="debtor = !debtor"
        >
          <buttonCollapse
            :item="debtor"
          />
          <h5 class="mb-0 text-primary ml-1">
            Выписка по должнику
          </h5>
        </div>

        <!--        v-b-modal:debtor-->
        <b-button
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>

      <div
        v-if="debtor"
        class="mt-2"
      >
        <div class="d_flex">
          <span>Наименование ЮЛ:</span>
          <span class="ml-50 text-right">{{ legalContact.FULL_NAME }}</span>
        </div>
        <div class="d_flex">
          <span>Наименование ТО/регион</span>
          <span class="ml-50 text-right"> {{ legalContact.REGION }} </span>
        </div>
        <div class="d_flex">
          <span>ИНН</span>
          <span class="ml-50 text-right"> {{ legalContact.INN }} </span>
        </div>
        <div class="d_flex">
          <span>Имущество</span>
          <span
            class="ml-50 text-primary cursor-pointer"
            @click="openPropertyModal"
          >
            Открыть
          </span>
        </div>
        <div class="d_flex">
          <span>Прибыль компании/выручка</span>
          <span class="ml-50 text-right"> {{ legalContact.COMPANY_PROFIT }} </span>
        </div>
        <div class="d_flex">
          <span>Юридический адрес</span>
          <span class="ml-50 text-right"> {{ legalContact.ADDRESS_YL }} </span>
        </div>
        <div class="d_flex">
          <span>Фактический адрес</span>
          <span class="ml-50 text-right"> {{ legalContact.HOME_ADDRESS }} </span>
        </div>
        <div>
          <span v-b-toggle.collapse-12>
            Контакты
            <feather-icon
              class="text-primary"
              icon="ChevronDownIcon"
            />
          </span>
          <b-collapse
            id="collapse-12"
            class="ml-2"
          >
            <div class="d_flex mt-1">
              <span>Система налогообложения:</span>
              <span class="ml-50 text-right"> {{ legalContact.TAX_SYSTEM }} </span>
            </div>
            <div class="d_flex">
              <span>Городской телефон:</span>
              <span class="ml-50 text-right">-</span>
            </div>
            <div class="d_flex">
              <span>Мобильный телефон:</span>
              <span class="ml-50 text-right"> - </span>
            </div>

            <div class="d_flex ">
              <span>Рабочий телефон:</span>
              <span class="ml-50 text-right"> - </span>
            </div>

            <div class="d_flex">
              <span>Дополнительный телефон:</span>
              <span class="ml-50 text-right"> -</span>
            </div>
            <div class="d_flex">
              <span>E-mail:</span>
              <span class="ml-50 text-right">
                <div
                  v-for="(item, index) in legalContact.EMAIL"
                  :key="index"
                  @click.stop
                >
                  <a
                    :href="'mailto:' + item"
                    class="ml-50 text-right"
                    v-html="item"
                  />
                </div>
              </span>
            </div>
            <div class="d_flex">
              <span>Сайт:</span>
              <span class="ml-50 text-right"> {{ legalContact.WEB_SITE }} </span>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-card>
    <!--    Выписка по должнику-->

    <!--    Финансовое состояние-->
    <b-card
      no-body
      class="p-1 mb-1"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="finance = !finance"
        >
          <buttonCollapse
            :item="debtor"
          />
          <h5 class="mb-0 text-primary ml-1">
            Финансовое состояние
          </h5>
        </div>

        <b-button
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>

      <div
        v-if="finance"
        class="mt-2"
      >
        <div class="d_flex">
          <span>Количество долгов:</span>
          <span class="ml-50 text-right"> {{ legalContact.total_count }} </span>
        </div>
        <div class="d_flex">
          <b class="text-danger">Сумма долгов</b>
          <b class="ml-50 text-danger text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_SUM) }} </b>
        </div>
        <div class="d_flex">
          <span>Количество судебных дел</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.JUDICAL_WORK_COUNT }}</span>
        </div>
        <div class="d_flex">
          <span>Прибыль компании/выручка</span>
          <span class="ml-50 text-right"> {{ legalContact.COMPANY_PROFIT }} </span>
        </div>
        <div class="d_flex">
          <span>Счета и движения по счетам</span>
          <span class="ml-50 text-right"> - </span>
        </div>
        <div class="d_flex">
          <span>Тип кредита</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.TYPE_CREDIT }} </span>
        </div>
        <div class="d_flex">
          <span>Предмет договора</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.SUBJECT_AGREEMENT }} </span>
        </div>
        <div class="d_flex">
          <span>№ КД (айди договора)</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.DEBT_TYPE }} </span>
        </div>
        <div class="d_flex">
          <span>Дата выдачи кредита</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.sk_borrower_debt }} </span>
        </div>
        <div class="d_flex">
          <span>Дата окончания договора</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.DEBT_END_DATE }} </span>
        </div>
        <div class="d_flex">
          <span>Сумма кредита</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.DEBT_END_DATE }} </span>
        </div>
        <div class="d_flex">
          <span>Срок кредита</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.DEBT_PERIOD }} </span>
        </div>
        <div class="d_flex">
          <span>Размер процентной ставки</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.DEBT_PERIOD }} </span>
        </div>
        <div class="d_flex">
          <span>Дата выхода на просроченную задолженность</span>
          <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.DEBT_DATE }} </span>
        </div>
        <div class="d_flex">
          <span>Количество дней просрочки</span>
          <span class="ml-50"> {{ legalContact.sk_borrower_debt.BASIS.DELAY }} </span>
        </div>
        <div v-if="legalContact.sk_borrower_debt.BASIS">
          <span v-b-toggle.collapse-1>
            Оплата
            <feather-icon
              class="text-primary"
              icon="ChevronDownIcon"
            />
          </span>
          <b-collapse
            id="collapse-1"
            class="ml-2"
          >
            <div class="d_flex mt-1">
              <span>Валюта кредита:</span>
              <span class="ml-50 text-right"> {{ legalContact.sk_borrower_debt.BASIS.CURRENCY }} </span>
            </div>
            <div class="d_flex">
              <span class="text-left">Общая сумма задолженности:</span>
              <span class="ml-50 text-right">{{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT) }}</span>
            </div>
            <div class="d_flex text-left">
              <span>Сумма задолженности по основному долгу :</span>
              <span class="ml-50 text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_AMOUNT) }} </span>
            </div>

            <div class="d_flex ">
              <span class="text-left">Сумма задолженности по процентам:</span>
              <span class="ml-50 text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_INTEREST) }} </span>
            </div>

            <div class="d_flex">
              <span class="text-left">Сумма задолженности по неустойке признанной судом:</span>
              <span class="ml-50 text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_PENALTY_FOR_COURT) }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Сумма задолженности по комиссиям:</span>
              <span class="ml-50 text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_COMMISSIONS) }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Сумма задолженности по оборудованию, технике:</span>
              <span class="ml-50 text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_EQUIPMENT) }}</span>
            </div>

            <div class="d_flex">
              <span class="text-left">Сумма задолженности по госпошлине:</span>
              <span class="ml-50 text-right"> {{ $_moneyFormatter(legalContact.sk_borrower_debt.BASIS.DEBT_ARREARS) }}</span>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-card>
    <!--    Финансовое состояние-->

    <b-card
      no-body
      class="p-1 mb-1"
      indicators
    >
      <div class="d_flex mb-0">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="caseCourt = !caseCourt"
        >
          <buttonCollapse
            :item="caseCourt"
          />
          <h5 class="mb-0 ml-1 text-primary">
            Судебное дело
          </h5>
        </div>

        <b-button
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>
      <b-carousel
        v-if="caseCourt"
        id="carousel-1"
        ref="myCarousel"
        :interval="0"
        indicators
        class="mt-2"
      >
        <p
          v-if="LegalJudicalWork.count === 0"
          class="text-center"
        >
          Нет данных
        </p>
        <b-carousel-slide
          v-for="(item, index) in LegalJudicalWork.results"
          v-else
          :key="index"
          class="carousel-inner"
        >
          <template #img>
            <div class="d_flex">
              <span>Правопреемство</span>
              <span>{{ item.SUCCESSION ? 'Да' : 'Нет' }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Просужен</span>
              <span class="text-right">-</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Дата отправки заявления в суд</span>
              <span class="text-right">{{ item.JUD_DATE_SENT }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Вид последнего судебного заявления</span>
              <span class="text-right">{{ item.JUD_TYPE }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Cумма исковых требований,указанных в заявлении</span>
              <span class="text-right">{{ $_moneyFormatter(item.AMOUNT_CLAIMS) }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Cумма оплаченной ГП</span>
              <span class="text-right">{{ $_moneyFormatter(item.JUD_GP) }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Наличие судебного решения</span>
              <span class="text-right">{{ item.has_adjudgement ? 'Да' : 'Нет' }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Дата судебного решения</span>
              <span class="text-right">{{ item.adjudgement_date }}</span>
            </div>
            <div class="d_flex">
              <span class="text-left">Сумма решения</span>
              <span class="text-right">{{ $_moneyFormatter(item.adjudgement_sum) }}</span>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <div
        v-if="caseCourt"
        class="d-flex align-items-center justify-content-between"
      >
        <div
          v-if="LegalJudicalWork.count !== 0"
          class="p-50 cursor-pointer"
          @click="prev"
        >
          <feather-icon
            size="20"
            icon="ChevronLeftIcon"
            class="text-primary"
          />
        </div>

        <div
          v-if="LegalJudicalWork.count !== 0"
          class="p-50 cursor-pointer"
          @click="next"
        >
          <feather-icon
            size="20"
            icon="ChevronRightIcon"
            class="text-primary"
          />
        </div>
      </div>
    </b-card>

    <b-card
      no-body
      class="p-1 mb-1"
      indicators
    >
      <div class="d_flex mb-0">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="execution = !execution"
        >
          <buttonCollapse
            :item="execution"
          />
          <h5 class="mb-0 ml-1 text-primary">
            Исполнение
          </h5>
        </div>

        <b-button
          variant="warning"
          size="sm"
          class="p-50"
        >
          <feather-icon
            icon="Edit3Icon"
          />
        </b-button>
      </div>
      <b-carousel
        v-if="execution"
        id="carousel-1"
        ref="myCarousel2"
        :interval="0"
        indicators
        class="mt-2"
      >
        <p
          v-if="LegalJudicalExecution.count === 0"
          class="text-center"
        >
          Нет данных
        </p>

        <b-carousel-slide
          v-for="(item2, index2) in LegalJudicalExecution.results"
          :key="index2"
          class="carousel-inner"
        >
          <template #img>
            <div class="d-flex justify-content-between mb-1">
              <span>Дата исполнительного листа</span>
              <span>{{ item2.EXECUTIVE_DATE }}</span>
            </div>
            <div class="d-flex justify-content-between mb-1">
              <span class="text-left">Дата ИП:</span>
              <span class="text-right">{{ item2.EXECUTIVE_WORK && item2.EXECUTIVE_WORK.SSP_IP_START_DATE }}</span>
            </div>
            <div class="d-flex justify-content-between mb-1">
              <span class="text-left">Номер ИП</span>
              <span class="text-right">{{ item2.EXECUTIVE_WORK && item2.EXECUTIVE_WORK.SSP_NUMBER_IP }}</span>
            </div>
            <div class="d-flex justify-content-between mb-1">
              <span class="text-left">Наличие актов о невозможности взыскания:</span>
              <span class="text-right">{{ item2.EXECUTIVE_WORK && item2.EXECUTIVE_WORK.ACTS_IMPOSSIBILITY_RECOVERY }}</span>
            </div>
            <div class="d-flex justify-content-between mb-1">
              <span class="text-left">Наличие скан.копии свидетельства о смерти:</span>
              <span class="text-right">-</span>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <div
        v-if="execution"
        class="d-flex align-items-center justify-content-between"
      >
        <div
          v-if="LegalJudicalExecution.count !== 0"
          class="p-50 cursor-pointer"
          @click="prev2"
        >
          <feather-icon
            size="20"
            icon="ChevronLeftIcon"
            class="text-primary"
          />
        </div>

        <div
          v-if="LegalJudicalExecution.count !== 0"
          class="p-50 cursor-pointer"
          @click="next2"
        >
          <feather-icon
            size="20"
            icon="ChevronRightIcon"
            class="text-primary"
          />
        </div>
      </div>
    </b-card>

    <!--modal-->
    <debtorModal />

    <!--    Имущество-->
    <TheDebtorPropertyModal
      ref="propertyModal"
      @propertyRefresh="propertyRefresh"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCarousel, BCarouselSlide, BCollapse, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import buttonCollapse from '@/components/buttonCollapse.vue'
import TheDebtorPropertyModal from '@/modules/statementDebtor/components/TheDebtorPropertyModal.vue'
import debtorModal from '../AddEditModal/debtor.vue'

export default {
  components: {
    BOverlay,
    TheDebtorPropertyModal,
    BCarouselSlide,
    BCarousel,
    BCollapse,
    BCard,
    BButton,
    buttonCollapse,
    debtorModal,
  },
  data() {
    return {
      debtor: true,
      finance: false,
      caseCourt: false,
      execution: false,
      busy: false,
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['legalContact', 'LegalJudicalWork', 'LegalJudicalExecution']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('legalPortfoliosCollector', ['FETCH_LEGAL_CONTACT', 'FETCH_LEGAL_JUDICAL_WORK', 'FETCH_LEGAL_JUDICAL_EXECUTION', 'UPDATE_BASIS_BASIS']),
    ...mapActions('cases', ['FETCH_DEBT_PROPERTY_MODAL']),

    openPropertyModal() {
      this.$refs.propertyModal.open()
    },

    propertyRefresh(data) {
      this.FETCH_DEBT_PROPERTY_MODAL({
        id: this.legalContact.id,
        ...data,
      })
    },

    prev() {
      this.$refs.myCarousel.prev()
    },
    next() {
      this.$refs.myCarousel.next()
    },
    prev2() {
      this.$refs.myCarousel2.prev()
    },
    next2() {
      this.$refs.myCarousel2.next()
    },

    refresh() {
      this.FETCH_LEGAL_CONTACT({
        id: this.$route.params.id,
      }).then(() => {
        this.FETCH_LEGAL_JUDICAL_WORK({ DOSSIER_ID: this.legalContact.sk_borrower_debt.id })
        this.FETCH_LEGAL_JUDICAL_EXECUTION({ DOSSIER_ID: this.legalContact.sk_borrower_debt.id })
      })
    },

    update3RD() {
      this.$_showAreYouSureYesNo()
        .then(res => {
          if (res) {
            this.busy = true
            const { REFUSED_FROM_3RD_PARTIES, id } = this.legalContact.sk_borrower_debt.BASIS

            const newData = {
              id,
              data: {},
            }

            if (REFUSED_FROM_3RD_PARTIES === 'N') {
              newData.data.REFUSED_FROM_3RD_PARTIES = 'Y'
            } else if (REFUSED_FROM_3RD_PARTIES === 'Y') {
              newData.data.REFUSED_FROM_3RD_PARTIES = 'N'
            } else if (REFUSED_FROM_3RD_PARTIES === '' || REFUSED_FROM_3RD_PARTIES === 'None' || REFUSED_FROM_3RD_PARTIES === null) {
              newData.data.REFUSED_FROM_3RD_PARTIES = 'Y'
            }

            this.UPDATE_BASIS_BASIS(newData)
              .then(() => {
                this.$_okToast()
                this.FETCH_LEGAL_CONTACT({ id: this.$route.params.id })
                this.busy = false
              })
              .catch(e => {
                this.$_errorToast(e)
                this.busy = false
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.d_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.client_info {
  .carousel-indicators {
    bottom: -45px;

    li {
      border-radius: 100%;
      height: 8px;
      width: 8px;
      background-color: #3646af;
    }
  }
}
</style>
