<template>
  <div>
    <b-row>
      <!-------------------history timeline--------------->
      <b-col md="6">
        <b-card
          no-body
          class="time__med"
        >
          <div class="d_flex mb-1">
            <div class="d-flex align-items-center cursor-pointer">
              <h5 class="mb-0 text-primary">
                История статуса
              </h5>
            </div>
          </div>
          <div
            style="height: 220px"
            class="overflow-auto"
          >
            <app-timeline
              class="mt-2 position-relative pr-1"
            >
              <LazyScroll
                :other-params="{
                  page_size: 5,
                }"
                :store-fetch="getFunc"
                :store-list="historyTimeLine"
              >
                <app-timeline-item
                  v-for="(e, inx) in historyTimeLine.results"
                  :key="inx"
                  class="pb-2"
                  :variant="e.status === 'light-danger' ? 'light_dann' : e.status"
                >
                  <div class="d-flex justify-content-between">
                    <div class="mb-sm-0">
                      <h6 class="mb-0">
                        {{ textHistory(e.status) }}
                      </h6>
                    </div>
                    <div class="d-flex flex-column flex-wrap text-right">
                      <small class="mb-0 text-dark">
                        {{ e.created_date }}
                      </small>
                      <small
                        v-if="e.user"
                        class="timeline-item-time text-nowrap text-dark"
                      >
                        {{ e.user.full_name }}
                      </small>
                    </div>
                  </div>
                </app-timeline-item>
              </lazyscroll>

              <span
                v-if="historyTimeLine.count === 0"
                class="w-100 d-block text-center"
              >
                Нет данных
              </span>
            </app-timeline>
          </div>
        </b-card>
      </b-col>
      <!-------------------history timeline--------------->

      <!-------------------active card tabs--------------->
      <b-col
        md="3"
        class="vfor__cards"
      >
        <b-card
          v-for="(item, index) in cards"
          :key="index"
          :class="active === item.active ? 'acts2 acts' : 'acts'"
          no-body
          @click="getActive(item.active)"
        >
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              class="text-primary"
              :icon="item.icon"
              size="16"
            />
            <h5 class="mb-0 ml-50">
              {{ item.title }}
            </h5>
          </div>
        </b-card>

        <b-card
          v-b-toggle.sidebar-right
          class="acts"
          no-body
          @click="openComment"
        >
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              class="text-primary"
              :icon="'MessageSquareIcon'"
              size="16"
            />
            <h5 class="mb-0 ml-50">
              Комментарии
            </h5>
          </div>
        </b-card>
      </b-col>

      <!--      //////buttons-->
      <b-col
        md="3"
        class="three__btn"
      >
        <!-------------------statistic cards--------------->
        <statisticCards
          :active="active"
          @getActive="getActive"
        />
        <!-------------------statistic cards--------------->

        <b-card
          no-body
          :class="call ? 'assaaa mb-1' : 'mb-1 transition__assa'"
          class="cursor-pointer"
          style="padding: 17px 12px;"
          :bg-variant="call ? 'warning' : 'success'"
          @click="openCallTab"
        >
          <div class="d-flex align-items-center justify-content-between">
            <feather-icon
              icon="PhoneCallIcon"
              class="text-white ml-75"
            />
            <div
              style="width: 90%"
              class="text-center"
            >
              <span class="fs-13 text-white">
                Позвонить
              </span>
            </div>
          </div>
        </b-card>

        <!--        <b-button-->
        <!--          v-b-modal:autoCall-->
        <!--          class=" mb-1"-->
        <!--          block-->
        <!--          variant="dark"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="PhoneCallIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          Автодозвон-->
        <!--        </b-button>-->

        <!--        <b-button-->
        <!--          v-b-modal:manualSms-->
        <!--          class=" mb-1"-->
        <!--          block-->
        <!--          variant="info"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="MailIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          Ручное СМС-->
        <!--        </b-button>-->
      </b-col>
      <!-------------------active card tabs--------------->

      <!-------------------active modules--------------->
      <b-col md="12">
        <!--        pozvanit table-->
        <callTable v-if="call" />

        <otherMatters
          v-if="active === 1"
          @globalFetch="$emit('globalFetch')"
        />

        <attachedDocs v-if="active === 2" />

        <promiseTable v-if="active === 3" />

        <!--        header statistics-->
        <manualCll v-if="active === 4" />

        <autoCallPage v-if="active === 6" />

        <manualSmsPage v-if="active === 7" />

        <autoSmsPage v-if="active === 8" />

        <!--        header statistics-->

        <paymentHistory v-if="active === 5" />
      </b-col>
      <!-------------------active modules--------------->
    </b-row>

    <autoCall />
    <manualSms />
    <comment />
    <!--    v-if="active === 10"-->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
import LazyScroll from '@components/LazyScroll/LazyScroll.vue'
import otherMatters from '@/modules/legalPortfoliosCollector/components/collectorDetail/activeModules/otherMatters.vue'
import attachedDocs from '@/modules/legalPortfoliosCollector/components/collectorDetail/activeModules/attachedDocs/attachedDocs.vue'
// statistika/// /
import manualCll from '@/modules/legalPortfoliosCollector/components/collectorDetail/statistics/manualCll.vue'
import autoCallPage from '@/modules/legalPortfoliosCollector/components/collectorDetail/statistics/autoCll.vue'
import manualSmsPage from '@/modules/legalPortfoliosCollector/components/collectorDetail/statistics/manuaSms.vue'
import autoSmsPage from '@/modules/legalPortfoliosCollector/components/collectorDetail/statistics/autoSms.vue'
// statistika/// /
import statisticCards from './statisticCards.vue'
import comment from './activeModules/comment.vue'
import callTable from './buttonsModal/call.vue'
import paymentHistory from './activeModules/paymentHistory.vue'
import promiseTable from './activeModules/promises.vue'
import autoCall from './buttonsModal/autoCall.vue'
import manualSms from './buttonsModal/manualSms.vue'

export default {
  components: {
    LazyScroll,
    BRow,
    BCol,
    BCard,
    statisticCards,
    AppTimeline,
    AppTimelineItem,
    comment,
    callTable,
    paymentHistory,
    otherMatters,
    attachedDocs,
    promiseTable,
    autoCall,
    manualSms,
    manualCll,
    autoCallPage,
    manualSmsPage,
    autoSmsPage,
  },
  data() {
    return {
      call1: false,
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['cards', 'history', 'dkDebt', 'active', 'historyTimeLine', 'call', 'legalContact', 'dkDebtOther']),

    moment() {
      return moment
    },
  },
  watch: {
    legalContact: {
      handler() {
        const params = {
          page_size: 5,
          debt: this.legalContact.sk_borrower_debt.id,
          page: 1,
        }
        this.FETCH_HISTORY_TIMELINE(params)
        this.FETCH_DK_HEADER_STA({ debt_id: this.legalContact.sk_borrower_debt.id })
      },
    },
  },
  async mounted() {
    if (this.legalContact.sk_borrower_debt?.id) {
      const params = {
        page_size: 5,
        debt: this.legalContact.sk_borrower_debt.id,
        page: 1,
      }
      this.FETCH_HISTORY_TIMELINE(params)
    }
  },
  methods: {
    ...mapMutations('legalPortfoliosCollector', ['SET_ACTIVE', 'SET_CALL', 'SET_UTILS_COMMIT_EMPTY']),
    ...mapActions('legalPortfoliosCollector', ['FETCH_DK_DEBT_OTHER_SECOND', 'FETCH_HISTORY_TIMELINE', 'FETCH_DK_HEADER_STA', 'FETCH_DK_CALL_STATUS', 'FETCH_UTILS_COMMENT2']),

    openComment() {
      this.SET_UTILS_COMMIT_EMPTY()
      this.FETCH_UTILS_COMMENT2({
        content_type: this.legalContact.sk_borrower_debt.CONTENT_TYPE,
        object_id: this.legalContact.sk_borrower_debt.id,
        page: 1,
        page_size: 15,
      })
    },

    async getFunc() {
      const params = {
        page_size: 5,
        debt: this.legalContact.sk_borrower_debt.id,
        page: this.historyTimeLine.page === 1 ? 1 : this.historyTimeLine.next,
      }
      await this.FETCH_HISTORY_TIMELINE(params)
    },

    textHistory(text) {
      const messages = {
        secondary: 'Неправильный номер',
        primary: 'Связь прервалась',
        'light-danger': 'Отказ',
        dark: 'Банкрот',
        danger: 'Неперспективный',
        info: 'Не ответил',
        warning: 'Обещание',
      }
      return messages[text] || ''
    },

    getActive(active) {
      this.SET_ACTIVE(active)
      this.SET_CALL(false)
    },

    openCallTab() {
      if (this.time) {
        cancelAnimationFrame(this.time)
      }

      const newCall = !this.call

      this.SET_CALL(newCall)

      if (newCall) {
        this.time = requestAnimationFrame(() => {
          this.FETCH_DK_CALL_STATUS({ debt_id: this.legalContact?.sk_borrower_debt.id })
          this.FETCH_DK_DEBT_OTHER_SECOND({ BORROWER: this.legalContact?.id, page_size: 5, id: this.legalContact.sk_borrower_debt.id })
          // this.FETCH_DK_CONTACT_BORROWER({ CONTACT: this.legalContact?.id, page_size: 20 }) //FETCH_DK_CONTACT_BORROWER
        })
      }
    },
  },
}
</script>
<style lang="scss">
.acts {
  padding: 13px;
  margin-bottom: 14px;
  cursor: pointer;
}

.acts2 {
  background-color: #147bfc !important;

  h5 {
    color: white;
  }

  svg {
    fill: #fff;
  }
}

.dark-layout {
  svg {
    fill: transparent;
  }
}

.assaaa {
  transform: scale(0.945)!important;
  transition: all .3s;
}

.transition__assa {
  transition: all .3s;
}

.timeline-variant-light_dann .timeline-item-point {
  background-color: rgba(240, 129, 130, 0.68) !important;
}

.timeline-variant-light_dann .timeline-item-point:before{
  background-color: rgba(240, 129, 130, 0.24) !important;
}

.paddinggg {
  padding: 7px 12px;
}

.timeline-variant-purple .timeline-item-point {
  background-color: #9452ff !important;
}

.timeline-variant-purple .timeline-item-point:before {
  background-color: rgba(148, 82, 255, 0.22) !important;
}
</style>
