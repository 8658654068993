<template>
  <BModal
    id="propertyModalId"
    size="lg"
    centered
    title="Редактировать имущество"
    ok-variant="warning"
    ok-title="Сохранить"
    cancel-title="Отмена"
    cancel-variant="outline-secondary"
    responsive
    no-close-on-backdrop
    @show="openShowModal"
    @ok="updateProperty"
  >
    <b-row>
      <b-col class="col-md-6">
        <b-form-group label="Дата">
          <VcDatePicker
            v-model="data.created_date"
            show-format="YYYY.MM.DD"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Vin/Условный">
          <BFormInput
            v-model="data.VIN"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Номер">
          <BFormInput
            v-model="data.NUMBER"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Марка">
          <BFormInput
            v-model="data.BRAND"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Дата приобратения">
          <VcDatePicker
            v-model="data.DATE_BUY"
            show-format="YYYY.MM.DD"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Источник">
          <VSelect
            v-model="data.SOURCE"
            label="title"
            :reduce="source => source.value"
            :options="SOURCE"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Статус">
          <VSelect
            v-model="data.STATUS"
            label="title"
            :reduce="status => status.value"
            :options="STATUS"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Дата продажи">
          <VcDatePicker
            v-model="data.DATE_SELL"
            show-format="YYYY.MM.DD"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Рег. органы">
          <VSelect
            v-model="data.REG_COMPANY"
            label="title"
            :reduce="company => company.value"
            :options="COMPANY"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Место расположения">
          <BFormInput
            v-model="data.LOCATION"
          />
        </b-form-group>
      </b-col>

      <b-col class="col-md-6">
        <b-form-group label="Описание">
          <BFormInput
            v-model="data.DESCRIPTION"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </BModal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import VcDatePicker from '@/components/VcDatePicker'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddEditModal',
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    VSelect,
    BRow,
    BCol,
    VcDatePicker,
  },
  props: {
    propertEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: {
        created_date: null,
        VIN: null,
        NUMBER: null,
        BRAND: null,
        DATE_BUY: null,
        SOURCE: null,
        STATUS: null,
        DATE_SELL: null,
        REG_COMPANY: null,
        LOCATION: null,
        DESCRIPTION: null,
      },
      STATUS: [
        {
          title: 'Запрет на регистрационные действия',
          value: 'register_prohibition',
        },
        {
          title: 'Арестовано',
          value: 'arrested',
        },
        {
          title: 'Реализовано',
          value: 'implemented',
        },
      ],
      SOURCE: [
        {
          title: 'Анкета',
          value: 'form',
        },
        {
          title: 'Кронос',
          value: 'kronos',
        },
        {
          title: 'ФССП',
          value: 'fssp',
        },
        {
          title: 'Внешняя БД',
          value: 'external_db',
        },
        {
          title: 'Договор',
          value: 'contract',
        },
      ],
      COMPANY: [
        {
          title: 'Росреетр',
          value: 'rosereestr',
        },
        {
          title: 'ГИБДД',
          value: 'gibdd',
        },
        {
          title: 'РосТехНадзор',
          value: 'rtn',
        },
        {
          title: 'Налоговая',
          value: 'fns',
        },
        {
          title: 'ПФР',
          value: 'pfr',
        },
        {
          title: 'ГИМС',
          value: 'gims',
        },
        {
          title: 'Другой',
          value: 'other',
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['debtListId']),
  },
  methods: {
    ...mapActions('cases', ['UPDATE_DEBT_PROPERTY', 'FETCH_DEBT_PROPERTY_MODAL']),

    openShowModal() {
      this.$nextTick(() => {
        if (this.propertEdit) {
          this.data.created_date = this.propertEdit.created_date
          this.data.VIN = this.propertEdit.VIN
          this.data.NUMBER = this.propertEdit.NUMBER
          this.data.BRAND = this.propertEdit.BRAND
          this.data.DATE_BUY = this.propertEdit.DATE_BUY
          this.data.SOURCE = this.propertEdit.SOURCE
          this.data.STATUS = this.propertEdit.STATUS
          this.data.DATE_SELL = this.propertEdit.DATE_SELL
          this.data.REG_COMPANY = this.propertEdit.REG_COMPANY
          this.data.LOCATION = this.propertEdit.LOCATION
          this.data.DESCRIPTION = this.propertEdit.DESCRIPTION
        }
      })
    },

    updateProperty() {
      this.UPDATE_DEBT_PROPERTY({
        id: this.propertEdit.id,
        ...this.data,
      })
        .then(() => {
          this.FETCH_DEBT_PROPERTY_MODAL({ id: this.debtListId.BORROWER.id })
          this.$_okToast()
        })
    },
  },
}
</script>
