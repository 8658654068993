<template>
  <div>
    <TheViewEditModal
      ref="viewEditModal"
      title="Должник"
      :input-data="inputData"
      :options="options"
      :keys="keys"
    />
  </div>
</template>

<script>
import TheViewEditModal from '@components/modules/TheViewEditModal.vue'
import vSelect from 'vue-select'

export default {
  components: {
    TheViewEditModal,
  },
  props: {
    debtor: {
      type: Object,
      default: () => {
      },
      required: false,
    },
  },
  data() {
    return {
      inputData: {
        id: '', // this.debtor.id
        PAY_DATE: '', // this.debtor.PAY_DATE
        PAY_AMOUNT: '', // this.debtor.PAY_AMOUNT
        PAY_OD: '',
        PAY_Proc: '',
        PAY_CHV: '',
        PAY_Peni_CHV: '',
        PAY_Peni: '',
        PAY_UY: '',
        PAY_GP: '',
        PAY_RF: '',
        EXECUTIVE_DOC_ID: '',
        sign_admission: '',
        collector_share: '',
        not_counted_manager: {},
      },
      keys: {
        PAY_DATE: {
          type: 'VcDatePicker',
          title: 'Дата платежа: ',
        },
        PAY_AMOUNT: {
          type: 'BFormInput',
          title: 'Сумма платежа итого:  ',
        },
        PAY_OD: {
          type: 'BFormInput',
          title: 'Сумма платежа ОД:  ',
        },
        PAY_Proc: {
          type: 'BFormInput',
          title: 'Сумма платежа Проценты:  ',
        },
        PAY_CHV: {
          type: 'BFormInput',
          title: 'Сумма платежа ЧВ:  ',
        },
        PAY_Peni_CHV: {
          type: 'BFormInput',
          title: 'Сумма платежа Пени ЧВ:  ',
        },
        PAY_Peni: {
          type: 'BFormInput',
          title: 'Сумма платежа Пени:  ',
        },
        PAY_UY: {
          type: 'BFormInput',
          title: 'Сумма платежа ЮУ:  ',
        },
        PAY_GP: {
          type: 'BFormInput',
          title: 'Сумма платежа ГП:  ',
        },
        PAY_RF: {
          type: 'BFormInput',
          title: 'Сумма платежа РФ:  ',
        },
        // EXECUTIVE_DOC_ID: {
        //   type: 'VSelect',
        //   title: 'Исполнительный документ:  ',
        // },
        // sign_admission: {
        //   type: 'VSelect',
        //   title: 'Признак поступления: ',
        // },
        // collector_share: {
        //   title: 'Доля коллектора:  ',
        // },
        // not_counted_manager: {
        //   type: vSelect,
        //   reduce: v => v && v.title,
        //   title: 'Не засчитывается менеджеру:',
        // },
      },

      options: {
        not_counted_manager: [
          {
            id: 1,
            value: '123',
            title: '123',
          },
          {
            id: 2,
            value: '123',
            title: '123',
          },
          {
            id: 3,
            value: '123',
            title: '123',
          },
        ],
      },
    }
  },
  methods: {
    open() {
      this.inputData = this.debtor
      this.$nextTick(() => {
        this.$refs.viewEditModal.open()
      })
    },
    close() {
      this.$refs.viewEditModal.close()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
