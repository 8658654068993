<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      v-model="comment"
      bg-variant="white"
      right
      shadow
    >
      <template #header>
        <div
          class="xClass"
          @click="comment = false"
        >
          <feather-icon icon="XIcon" />
        </div>
      </template>

      <div class="mt-2 overflow-auto px-2">
        <b-overlay
          :show="loading"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            class="scroll-area"
            style="height: 600px"
            @ps-y-reach-end="fetchData2"
          >
            <empty
              v-if="!utilsComments2.count"
              style="    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);"
            />
            <div
              v-for="(c, inx) in utilsComments2.results"
              v-else
              :key="inx"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <b-avatar-group>
                    <b-avatar
                      v-b-tooltip.hover
                      :src="c.author.profile_picture"
                      class="pull-up"
                      :title="c.author.first_name"
                    />
                  </b-avatar-group>

                  <b-card
                    class="p-1 mb-50 d-flex flex-column ml-1 w-75"
                    no-body
                  >
                    <h6 class="mb-0">
                      {{ c.message }}
                    </h6>
                    <small
                      style="font-size: 10px"
                      class="text-right mt-25"
                    >
                      {{ c.created_date }}
                    </small>

                    <div class="position-absolute position-right-0 position-top-0 mt-25 mr-25 cursor-pointer">
                      <b-dropdown
                        id="dropDel"
                        variant="link"
                        no-caret
                        class="chart-dropdown"
                        toggle-class="p-0"
                        left
                        style="width: 10px"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            class="align-middle"
                          />
                        </template>
                        <b-dropdown-item @click="deleteComment(c.id)">
                          <feather-icon
                            class="text-danger"
                            icon="Trash2Icon"
                            size="16"
                          />
                        </b-dropdown-item>
                      </b-dropdown>

                    </div>
                  </b-card>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </b-overlay>
      </div>

      <div class="form__com mx-2">
        <input
          v-model="comments.message"
          placeholder="Напишите сообщение"
          @keydown.enter="postComment"
        >

        <b-button
          size="sm"
          class="p-50"
          :variant="!comments.message ? 'secondary' : 'primary'"
          :disabled="!comments.message"
          @click="postComment"
        >
          <img
            height="20"
            :src="require('@/assets/images/Plain.svg')"
            alt="#"
          >
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BAvatarGroup,
  BSidebar, VBToggle,
  BCard,
  BDropdown,
  BDropdownItem,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import areYouSure from '@/mixins/areYouSure'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions, mapMutations, mapState } from 'vuex'
import empty from '@components/empty.vue'

export default {
  components: {
    empty,
    BButton,
    BAvatar,
    BAvatarGroup,
    BSidebar,
    BCard,
    BDropdown,
    BDropdownItem,
    BOverlay,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [areYouSure],
  data() {
    return {
      comment: false,
      loading: false,
      comments: {
        message: '',
      },
    }
  },
  computed: {
    ...mapState('legalPortfoliosCollector', ['utilsComments2', 'dkDebt', 'legalContact']),

    getUserData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
  },
  // watch: {
  //   legalContact: {
  //     handler() {
  //       this.refresh()
  //     },
  //   },
  // },
  methods: {
    ...mapActions('legalPortfoliosCollector', ['FETCH_UTILS_COMMENT2', 'CREATE_UTILS_COMMENT', 'DELETE_COMMENT']),
    ...mapMutations('legalPortfoliosCollector', ['SET_UTILS_COMMIT_EMPTY']),

    refresh() {
      this.FETCH_UTILS_COMMENT2({
        content_type: this.legalContact.sk_borrower_debt.CONTENT_TYPE,
        object_id: this.legalContact.sk_borrower_debt.id,
        page: 1,
        page_size: 15,
      })
    },

    async fetchData2() {
      if (typeof this.utilsComments2.next === 'string') {
        return
      }
      if (this.loading || !this.utilsComments2.next) {
        return
      }
      try {
        this.loading = true
        await this.FETCH_UTILS_COMMENT2({
          content_type: this.legalContact.sk_borrower_debt.CONTENT_TYPE,
          object_id: this.legalContact.sk_borrower_debt.id,
          page: this.utilsComments2.next,
          page_size: 20,
        })
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },

    postComment() {
      if (this.comments.message) {
        this.CREATE_UTILS_COMMENT({
          author: this.getUserData.id,
          content_type: this.legalContact.sk_borrower_debt.CONTENT_TYPE,
          object_id: this.legalContact.sk_borrower_debt.id,
          ...this.comments,
        })
          .then(() => {
            this.SET_UTILS_COMMIT_EMPTY()
            this.$nextTick(() => {
              this.scrollToBottomInChatLog()
            })
            this.comments.message = ''
            this.refresh()
          })
      }
    },

    scrollToBottomInChatLog() {
      this.$nextTick(() => {
        const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
        scrollEl.scrollTop = scrollEl.scrollHeight
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.SET_UTILS_COMMIT_EMPTY()
                this.refresh()
                this.$_okToast()
              })
          }
        })
        .catch(e => this.$_errorToast(e))
    },
  },
}
</script>
<style lang="scss">
#dropDel .dropdown-menu {
  min-width: 4rem;
}

#sidebar-right {
  width: 500px;
  height: 80% !important;
  top: 11%;
  border-radius: 10px;
}

#sidebar-right .b-sidebar-header .xClass:hover {
  background-color: red;
  color: #fff;
  cursor: pointer;
}

#sidebar-right .b-sidebar-header .xClass {
  position: absolute;
  top: 2px;
  left: 0;
  width: 35px;
  height: 35px;
  border-radius: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
}

.dark-layout {
  .form__com {
    background-color: #283046;
    border: 1px solid;

    input {
      color: #fff;
    }

    input::placeholder {
      color: #ccc;
    }
  }
}

.form__com {
  border-radius: 12px;
  background: var(--50, #F0F2F5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  position: absolute;
  width: 90%;
  margin: 0 auto;
  bottom: 20px;
  height: 89px;

  input {
    border: none;
    outline: none;
    width: 90%;
    background-color: transparent;
    padding: 8px;
  }
}

.user-chats {
  height: 600px;
}
</style>
