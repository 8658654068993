<template>
  <BModal
    ref="modal"
    size="xl"
    centered
    title="Имущество"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    responsive
    no-close-on-backdrop
  >
    <div class="d-flex align-items-center justify-content-between">
      <p
        class="text-nowrap hover-text-underline mb-0"
        @click="additionalFields = !additionalFields"
      >
        {{ additionalFields ? "Меньше" : "Больше" }} полей
      </p>
    </div>
    <div class="overflow-auto">
      <BTable
        class="my-2 pb-2"
        :items="DEBTProperty.results"
        :fields="getFields"
        show-empty
        empty-text="Нет данных"
      >
        <template #cell(created_date)="props">
          {{ props.item.created_date }}
        </template>

        <template #cell(no_name)="props">
          <p
            class="a__click"
            @click="openDocumentsModal(props.item)"
          >
            Документы
          </p>
          <p
            class="a__click"
            @click="openModal(props.item)"
          >
            Комментариев
          </p>
        </template>

        <template #cell(STATUS)="props">
          <span v-if="props.item.STATUS === 'register_prohibition'">Запрет на регистрационные действия</span>
          <span v-if="props.item.STATUS === 'arrested'">Арестовано</span>
          <span v-if="props.item.STATUS === 'implemented'">Реализовано</span>
        </template>

        <template #cell(SOURCE)="props">
          <span v-if="props.item.SOURCE === 'form'">Анкета</span>
          <span v-if="props.item.SOURCE === 'kronos'">Кронос</span>
          <span v-if="props.item.SOURCE === 'fssp'">ФССП</span>
          <span v-if="props.item.SOURCE === 'external_db'">Внешняя БД</span>
          <span v-if="props.item.SOURCE === 'contract'">Договор</span>
        </template>

        <template #cell(REG_COMPANY)="props">
          <span v-if="props.item.REG_COMPANY === 'rosereestr'">Росреетр</span>
          <span v-if="props.item.REG_COMPANY === 'gibdd'">ГИБДД</span>
          <span v-if="props.item.REG_COMPANY === 'rtn'">РосТехНадзор</span>
          <span v-if="props.item.REG_COMPANY === 'fns'">Налоговая</span>
          <span v-if="props.item.REG_COMPANY === 'pfr'">ПФР</span>
          <span v-if="props.item.REG_COMPANY === 'gims'">ГИМС</span>
          <span v-if="props.item.REG_COMPANY === 'other'">Другой</span>
        </template>

        <template #cell(actions)="props">
          <BButton
            class="p-50 mr-1"
            variant="warning"
            @click="editProperty(props.item)"
          >
            <feather-icon icon="EditIcon" />
          </BButton>
          <BButton
            class="p-50"
            variant="danger"
            @click="deleteProperty(props.item.id)"
          >
            <feather-icon icon="XIcon" />
          </BButton>
        </template>
      </BTable>
      <BCPagination
        :go-to-page="false"
        :count="DEBTProperty.count"
        @input="onRefreshPagination"
      />
    </div>

    <!--//comment modal-->
    <modalComment
      ref="comments"
      :comment="comments"
      :comment-message="commentDebt.results"
      @createComment="createComment"
      @deleteComment="deleteComment"
    />
    <!--//comment modal-->

    <!--//document modal-->
    <theDocClaimsModal
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @changePag="changePag"
    />
    <!--//document modal-->

    <!--add edit property modal-->
    <propertyodal
      :propert-edit="propertEdit"
    />
    <!--add edit property modal-->
  </BModal>
</template>

<script>
import { BButton, BModal, BTable } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import useJwt from '@/auth/jwt/useJwt'
import theDocClaimsModal from '@/modules/claimsLitigation/claimsModal/doc/theDocClaimsModal.vue'
import propertyodal from '../Property/addEditModal.vue'
import modalComment from '../Property/commentsModal.vue'

export default {
  name: 'TheDebtorIncomeModal',
  components: {
    BModal,
    BTable,
    BButton,
    propertyodal,
    theDocClaimsModal,
    modalComment,
  },
  mixins: [areYouSure],
  data() {
    return {
      additionalFields: true,
      fields: [
        {
          label: 'Дата',
          key: 'created_date',
          sortable: false,
        },
        {
          label: 'Vin/условный',
          key: 'VIN',
          additional: true,
        },
        {
          label: 'Номер',
          key: 'NUMBER',
          additional: true,
        },
        {
          label: 'Марка',
          key: 'BRAND',
          additional: true,
        },
        {
          label: 'Дата приобратения',
          key: 'DATE_BUY',
        },
        {
          label: 'Источник',
          key: 'SOURCE',
        },
        {
          label: 'Статус',
          key: 'STATUS',
        },
        {
          label: 'Дата продажи',
          key: 'DATE_SELL',
        },
        {
          label: 'Рег. органы',
          key: 'REG_COMPANY',
          additional: true,
        },
        {
          label: 'Место расположения',
          key: 'LOCATION',
          additional: true,
        },
        {
          label: 'Описание',
          key: 'DESCRIPTION',
        },
        {
          label: '',
          key: 'no_name',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      propertEdit: {},
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
      },
      comments: {
        message: '',
      },
      contentTypeClaim: null,
      objectIdClaim: null,
    }
  },
  computed: {
    ...mapState('cases', [
      'DEBTProperty',
      'debtDocument',
      'commentDebt',
      'debtListId',
    ]),

    getFields() {
      if (this.additionalFields) {
        return this.fields
      }
      return this.fields.filter(item => !item.additional)
    },

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },
  },

  methods: {
    ...mapActions('cases', [
      'DELETE_DEBT_PROPERTY_MODAL',
      'FETCH_DEBT_PROPERTY_MODAL',
      'DELETE_DEBT_DOCUMENT',
      'FETCH_DEBT_DOCUMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
      'CREATE_COMMENT',
      'FETCH_COMMENT_DEBT',
      'DELETE_COMMENT',
    ]),
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },

    onRefreshPagination(data) {
      this.$emit('propertyRefresh', data)
    },

    // *********************** Property *****************************//
    editProperty(item) {
      this.propertEdit = item
      this.$nextTick(() => {
        this.$bvModal.show('propertyModalId')
      })
    },

    deleteProperty(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_DEBT_PROPERTY_MODAL(id)
              .then(() => {
                this.FETCH_DEBT_PROPERTY_MODAL({ id: this.debtListId.BORROWER.id })
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },
    // *********************** Property *****************************//

    // *********************** document *****************************//
    openDocumentsModal(item) {
      this.$bvModal.show('modalClaimsDoc')
      this.contentTypeClaim = item.CONTENT_TYPE
      this.objectIdClaim = item.id
      this.FETCH_DEBT_DOCUMENT({
        content_type: item.CONTENT_TYPE,
        object_id: item.id,
      })
    },

    changePag(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.contentTypeClaim,
        object_id: this.objectIdClaim,
        ...data,
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      formData.append('file', this.formData?.file)
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.contentTypeClaim)
      formData.append('object_id', this.objectIdClaim)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEdiClaimstDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        // formData.append('file', this.formData?.file)
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.contentTypeClaim)
      formData.append('object_id', this.objectIdClaim)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEdiClaimstDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.file_title = null
      })
    },

    docRefresh() {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.contentTypeClaim,
        object_id: this.objectIdClaim,
      })
    },
    // ******************** document end **************************//

    // ******************* comments methods *********************** //
    createComment() {
      this.CREATE_COMMENT({
        author: this.getUserId,
        content_type: this.contentTypeClaim,
        object_id: this.objectIdClaim,
        ...this.comments,
      })
        .then(() => {
          this.refresh()
          this.$_okToast()
          this.comments.message = ''
        })
    },

    openModal(item) {
      this.$bvModal.show('modalComment')
      this.contentTypeClaim = item.CONTENT_TYPE
      this.objectIdClaim = item.id
      this.FETCH_COMMENT_DEBT({
        content_type: item.CONTENT_TYPE,
        object_id: item.id,
      })
    },

    refresh() {
      this.FETCH_COMMENT_DEBT({
        content_type: this.contentTypeClaim,
        object_id: this.objectIdClaim,
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
          }
        })
    },
    // ***************** comments methods end ********************* //
  },
}
</script>
