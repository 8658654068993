<template>
  <b-modal
    id="autoCall"
    title="Автопозвон"
    centered
    no-close-on-backdrop
    ok-title="Сохранить"
    cancel-title="Отмена"
  >
    <b-form-group label="Шаблон Автопозвон">
      <v-select />
    </b-form-group>

    <b-form-group label="Номер телефона">
      <b-form-input />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    VSelect,
  },
}
</script>

<style scoped>

</style>
